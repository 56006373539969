import axios from './../utils/axios';
import { notification } from 'antd';

export const API_TemplatedownloadFile2 = (folder,lvl,idFile) => {
    return axios({
        method: 'GET',
        url: `templates/checklist/${folder}/${lvl}/${idFile}`,
        responseType: 'blob',
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `checklistfile-${idFile}.docx`);
        document.body.appendChild(link);
        link.click();
    });
};
export const API_TemplatedownloadFile = (folder,lvl,idFile) => {
    return axios({
        method: 'GET',
        url: `templates/download/${folder}/${lvl}/${idFile}`,
        responseType: 'blob',
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        var excel=false;
        if ((lvl===3)||(lvl===5)) {
            if  ((idFile==="19")||(idFile==="20")) excel=true;
        }
        if (lvl===4) {
            if  ((idFile==="21")||(idFile==="22")) excel=true;
        }
        if (lvl===6) {
            if  ((idFile==="21")||(idFile==="20")) excel=true;
        }
        if ((lvl===2)||(lvl===8)) {
            if  (idFile==="17") excel=true;
        }
        console.log(excel,lvl,idFile)
        if (!excel) {
        link.setAttribute('download', `templatefile-${idFile}.docx`);
        } else {
        link.setAttribute('download', `templatefile-${idFile}.xlsx`);
        }
        document.body.appendChild(link);
        link.click();
    });
};
// может быть несколько файлов для разделов и вопросов
export const API_getListFiles = () => {
    return axios({
        method: 'GET',
        url: `files`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};



// может быть несколько файлов для разделов и вопросов
export const API_getListFilesOne = (id_parus) => {
    return axios({
        method: 'GET',
        url: `files/${id_parus}`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};
// может быть несколько файлов для разделов и вопросов
//valueid,course,form,question
export const API_getFiles = (valueid,course,form,question,desc) => {
    return axios({
        method: 'GET',
        url: `file/list/${valueid}/${course}/${form}/${question}?desc=${desc}`,
    });
};

export const API_getExpertFiles = (parentid) => {
    return axios({
        method: 'GET',
        url: `file/expertlist/${parentid}`,
    });
};


//API_uploadFileTemplate

export const API_uploadFileTemplate = json => {
//    const url = json.idFile ? `templates/update/${json.idFile}` : `templates/upload`;
    
    return axios({
//        method: json.idFile ? 'PUT' : 'POST',
        method: 'PUT',
        url: `templates/upload`,
        data: {
            idquestion: json.idquestion,
            fileName: json.fileName,
            fgos: json.fgos,
            fileBody: json.fileBody,
            lvl: json.lvl
        },
    });
};

export const API_deleteFile = idFile => {
    return axios({
        method: 'DELETE',
        url: `file/${idFile}`,
    }).then(function(response) {
        console.log(response.data);
        return response.data;
    });
};
export const API_addURL= json => {
    const url = json.idFile ? `url/update/${json.idFile}` : `url/upload`;
    return axios({
        method: json.idFile ? 'PUT' : 'POST',
        url: url,
        data: {
            comment: json.comment,
            idquestion: json.idquestion,
            idvalue: json.idvalue,
            course: json.course,
            form: json.form,
        },
    });
}
export const API_uploadFile = json => {
    const url = json.idFile ? `file/update/${json.idFile}` : `file/upload`;
    return axios({
        method: json.idFile ? 'PUT' : 'POST',
        url: url,
        data: {
            comment: json.comment,
            idquestion: json.idquestion,
            idvalue: json.idvalue,
            course: json.course,
            form: json.form,
            fileName: json.fileName,
            fileBody: json.fileBody
        },
    });
};

export const API_downloadFile = (idFile,namefile) => {
    return axios({
        method: 'GET',
        url: `file/download/${idFile}`,
        responseType: 'blob',
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', namefile);
        document.body.appendChild(link);
        link.click();
    });
};


export const API_ExpertdeleteFile = idFile => {
    return axios({
        method: 'DELETE',
        url: `/file/expertdeletefile/${idFile}`,
    });
};

export const API_ExpertuploadFile = json => {
    const url = json.idFile ? `file/expertupdate/${json.idFile}` : `file/expertupload`;
    return axios({
        method: json.idFile ? 'PUT' : 'POST',
        url: url,
        data: {
            comment: json.comment,
            idquestion: json.idquestion,
            idvalue: json.idvalue,
            course: json.course,
            form: json.form,
            fileid: json.fileid, // parent_id не путать с обычным id
            fileName: json.fileName,
            fileBody: json.fileBody
        },
    });
};

export const API_ExpertdownloadFile = (idFile,namefile) => {
    return axios({
        method: 'GET',
        url: `file/expertdownload/${idFile}`,
        responseType: 'blob',
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', namefile);
        document.body.appendChild(link);
        link.click();
    });
};

export const API_GetAboutFile = (id) => {
    return axios({
        method: 'GET',
        url: `file/${id}`,
    });
};



export const API_getListFilesExpert = () => {
    return axios({
        method: 'GET',
        url: `expertfiles`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};



// может быть несколько файлов для разделов и вопросов
export const API_getListFilesOneExpert = (id_parus) => {
    return axios({
        method: 'GET',
        url: `expertfiles/${id_parus}`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};
