import React from 'react'
import {Link } from 'react-router-dom';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Table} from 'antd';
import { useEffect, useState } from 'react';
import { API_GetQuestion} from '../api/question';
import {spandata} from './../api/addon';
import getColumnSearchProps from './../components/getColumnSearch'

const routes = [{ label: 'Главная', path: '/' }, { label: 'Разделы', path: '/docforstatement' }]


 

const GetQuestion = (props) => {

    const [getquiestion, setquestion] = useState([]);
    
    const columns = [
        {
            title: '№',
            dataIndex: 'rowNumber',
            key: 'rowNumber',
            sorter: (a, b) => a.rowNumber - b.rowNumber,
        },
        {
            title: 'Код',
            dataIndex: 'fieldsorted',
            key: 'fieldsorted',
            sorter: (a, b) => a.fieldsorted - b.fieldsorted,
        },
        { title: 'Наименование документа', 
            ...getColumnSearchProps('name','Наименование документа'),
            render: row => {
            return  spandata(row.name,row.design)
        }, key: 'name' },
        { title: '',  key: 'docname',
        render: row=> {
            if (row.code!==undefined) {
            return <Link 
            to={{
              pathname: `/question/${props.match.params.lvl}/${row.id}` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata('Перейти',10)}
            </Link>
            }
        },
        },
       ];
        
    
    useEffect(() => {
        API_GetQuestion(props.match.params.lvl).then(data => {
            let index = 0;
            setquestion(
                data.map(s => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        });
    }, [props.match.params.lvl]);

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Разделы" />
            <Table columns={columns} dataSource={getquiestion} rowKey ={record => record.id}  pagination={false}  
                        
            bordered size="small" />
        </>
    )
}

export default GetQuestion
