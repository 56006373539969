import React from 'react'
import {Link } from 'react-router-dom';
//import {SearchInput} from 'react-search-input';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { useEffect, useState } from 'react';
import { API_getListFilesExpert } from '../api/file';
import { Table} from 'antd';
import {spandata} from '../api/addon';
import {compareByAlph} from '../api/addon';
import getColumnSearchProps from '../components/getColumnSearch'

var moment = require('moment');

const routes = [{ label: 'Главная', path: '/' }, { label: 'Список файлов', path: '/files' }]

const FileListDocsExpert = () => {
    const [filelist, setfilelist] = useState([]);
 
    const columns = [
        {
            title: '№',
            dataIndex: 'rowNumber',
            key: 'rowNumber',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.rowNumber - b.rowNumber,
        },
        {
            title: 'Замечания',
            key: 'expert',
            render: row=> {
                return <Link 
                to={{
                  pathname: `/getexpertdoc/${row.id_parent}` 
                }}>
                {spandata('Посмотреть',10)}
                </Link>
                } 
    
        },
        {
            title: 'Информация',
            dataIndex: 'comment',
            key: 'comment',
            sorter: (a, b) => compareByAlph(a.comment, b.comment),
            ...getColumnSearchProps('comment','Комментарий'),
        },
        {
            title: 'Раздел',
            dataIndex: 'fieldsorted',
            key: 'fieldsorted',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.fieldsorted - b.fieldsorted,
    
        },
        {
            title: 'Имя файла',
            key: 'docname',
            ...getColumnSearchProps('docname','Имя файла'),
            render: row=> {
                return <Link 
                to={{
                  pathname: `/setdocument/${row.value_id}/${row.course}/${row.form}/${row.question_id}` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                }}>
                {spandata(row.docname,10)}
                </Link>
            },
           
        },
        {
            title: 'Изменено',
            dataIndex: 'dateupload',
            key: 'dateupload',
            sorter: (a, b) => moment(a.dateupload).unix() - moment(b.dateupload).unix()
        },
        {
            title: 'Наименование раздела',
            dataIndex: 'qname',
            key: 'qname',
            ...getColumnSearchProps('qname','название раздела'),
        }
        
    ];
    

    useEffect(() => {
        API_getListFilesExpert().then(responce => {
            let index = 0;
            setfilelist(
                responce.map(s => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        });
    }, []);

        
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Загруженные вами документы эксперта" />
            <Table columns={columns} dataSource={filelist} bordered size="small" 
            pagination={{
                pageSizeOptions: ["50","100","1000"],
                showSizeChanger: true,
              }}
                rowKey ={record => record.id} 
                />
        </>
    )
}

export default FileListDocsExpert
