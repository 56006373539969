import React from 'react';
import { Form, Input, Icon, Button} from 'antd';
import { API_login } from './../api/auth';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { setUser } from '../redux/actions';

const LoginForm = props => {
    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                API_login(values.username, values.password,values.type)
                    .then(response => {
                        props.setUser(values.username);
                        localStorage.setItem('accessToken', response.accessToken);
                        localStorage.setItem('login', values.username);
                        notification.success({ message: `Добро пожаловать ${values.username}!` });
                        props.history.push('/');
                    })
                    .catch(() => {
                        notification.error({ message: 'Пользователь с таким логином или паролем не найден' });
                    });
            }
        });
    };
    const { getFieldDecorator } = props.form;
    return (
        <Form onSubmit={handleSubmit} style={{ width: 300, margin: '0 auto' }}>
            <p style={{ fontWeight: 300, textAlign: 'center', fontSize: 14, marginBottom: 10 }}>Авторизация</p>
            <Form.Item style={{ marginBottom: 7 }}>
                {getFieldDecorator('username', {
                    required: false,
                    rules: [{ required: true, message: 'Введите логин' }],
                })(<Input autoFocus prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="введите логин БЕЗ @empl.s-vfu.ru!!!" size="large" />)}
            </Form.Item>
            <Form.Item style={{ marginBottom: 7 }}>
                {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Введите пароль' }],
                })(<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="введите пароль" size="large" />)}
            </Form.Item>
            
            <Form.Item style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit" block size="large">
                    Вход
                </Button>
            </Form.Item>
        </Form>
    );
};

const WrappedLoginForm = Form.create()(LoginForm);

export default connect(
    null,
    { setUser }
)(WrappedLoginForm);
