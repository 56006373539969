import React from 'react'
//import { useEffect } from 'react';
import { connect } from 'react-redux'
import { Icon, Button,Popover } from 'antd'
import { API_Offline } from '../../api/getheader';

const Header = props => {
    const logout = () => {
        API_Offline().then(response=>{
            console.log(response);
        });
        localStorage.removeItem('accessToken')
        props.history.push('/login')
    }
    
const content = (
    <div>
        Выход из модуля
    </div>
  );
  
    // https://cssgradient.io/
    return (
        <div
            style={{
                height: 50,
                background: 'linear-gradient(to right, #091979, #00d4ff)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 20,
                color: '#FFF',
            }}
        >
            <p style={{ fontSize: 24 }}>
                Модуль "Аккредитация"
            </p>
            <p style={{ fontSize: 16, marginRight: 20 }}>
                
                    <Icon type="user" style={{ marginRight: 10 }} />
                    {localStorage.getItem('login')}
                    <Popover content={content} title="Справка">
                <Button icon="poweroff" shape="circle" size="small" onClick={logout} />
                </Popover>
            </p>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        login: state.user.login,
    }
}

export default connect(mapStateToProps)(Header)
