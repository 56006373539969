// ООП программа берется uid
import axios from './../utils/axios';
import { notification } from 'antd';

export const API_GetQuestion = (lvl) => { // вытаскивает перечень документа согласно уровню 
    return axios({
        method: 'get',
        url: `questions/${lvl}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};
// должно выводить данные вместе с шаблона left join и данными которые введены
export const API_GetQuestionList = (valueid) => {
    return axios({
        method: 'get',
        url: `questions/list/${valueid}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_GetQuestionOne = id => {
    return axios({
        method: 'get',
        url: `question/${id}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_gettypes = () => {
    return axios({
        method: 'GET',
        url: `questiontypes/`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_SaveQuestion = jsonData => {
    return axios({
        method: jsonData.idQuesiton ? 'PUT' : 'POST',
        url: `question/saving`,
        data: {
            ...jsonData,
        },
    })
        .then(function(response) {
            notification.success({ message: 'Запрос выполнен' });
            return response.data.idQuesiton;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};