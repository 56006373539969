import React from 'react'
import { NavLink,Link } from 'react-router-dom';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { useEffect, useState } from 'react';
import { API_getstatoop } from '../api/task';
import { Table,Menu,Icon,Spin,notification} from 'antd';
import {spandata} from './../api/addon';
import { API_Online } from '../api/getheader';
import getColumnSearchProps from './../components/getColumnSearch'

const routes = [{ label: 'Главная', path: '/' }, { label: 'Статистика', path: '/stat' }]

const StatOOP = props  => {

    const getOnline = () => {
        API_Online().then(response => {
            if (parseInt(response.Status)===0) {
                notification.error({ message: `Ваша сессия истекла`, description: "Нужно перезайти" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
            if (parseInt(response.Status)===2) {
                notification.error({ message: `Авторизация успешна, но доступ закрыт`, description: "Ваши данные не найдены в рабочих группах модуля \"Аккредитации\" , обратитесь в директору/декану вашего учебного подразделения" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
        }).catch(function(error) {
            try {
                notification.error({ message: `Ошибка 1`, description: "неопознанная ошибка" });
                
            } catch {
                notification.error({ message: `Ошибка 2`, description: "неопознанная ошибка" });
            }
        });;
    };
    useEffect(() => {
            getOnline();
    // eslint-disable-next-line            
    }, []);

    const [loading, setLoading] = useState(false);

    
    const [taskinfo, settaskinfo] = useState([]);
    const columns = [
        {
            title: '№',
            key: 'rowNumber',
            dataIndex: 'rowNumber',
        },
        {
            title: '',
            key: 'status',
            render: taskinfo=> {
                if (taskinfo.status!=='готово')
                return (
                <Icon type="minus-circle" style={{ color: 'red' }} />
                )
                else 
                return (
                <Icon type="check-circle" style={{ color: 'green' }} />
                )
                
            },
        },
        
        {
            title: 'УЧП',
            dataIndex: 'fak',
            key: 'fak',
            ...getColumnSearchProps('fak','УЧП'),
        },
        {
            title: 'Код',
            dataIndex: 'code',
            key: 'code',
            ...getColumnSearchProps('code','Код направления'),
        },
        {
            title: 'Направление',
            dataIndex: 'napr',
            key: 'napr',
            ...getColumnSearchProps('napr','Направление'),
        },
        {
            title: 'Профиль',
            dataIndex: 'profile',
            key: 'profile',
            ...getColumnSearchProps('profile','Профиль'),
        },
        {
            title: 'Стандарт',
            dataIndex: 'fgos',
            key: 'fgos',
            ...getColumnSearchProps('fgos','ФГОС'),
        },
        {
            title: 'Очная форма',
            key: 'totalofo',
            children: [
                {
                    title: '1',
                    key: 'ofo1',
                    render: taskinfo=> {
                        return <Link 
                        to={{
                          pathname: `/tasksingle/${taskinfo.id}/1/1` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                        }}>
                        {spandata(taskinfo.ofo1,11)}
                        </Link>
                    },
                },
                {
            title: '2',
            key: 'totalofo2',
            render: taskinfo=> {
                return <Link 
                to={{
                  pathname: `/tasksingle/${taskinfo.id}/2/1` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                }}>
                {spandata(taskinfo.ofo2,11)}
                </Link>
            },
                },
        {
            title: '3',
            key: 'totalofo3',
            render: taskinfo=> {
                return <Link 
                to={{
                  pathname: `/tasksingle/${taskinfo.id}/3/1` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                }}>
                {spandata(taskinfo.ofo3,11)}
                </Link>
            },
        },
        {
            title: '4',
            key: 'totalofo4',
            render: taskinfo=> {
                return <Link 
                to={{
                  pathname: `/tasksingle/${taskinfo.id}/4/1` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                }}>
                {spandata(taskinfo.ofo4,11)}
                </Link>
            },
        },
        {
            title: '5',
            key: 'totalofo5',
            render: taskinfo=> {
                return <Link 
                to={{
                  pathname: `/tasksingle/${taskinfo.id}/5/1` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                }}>
                {spandata(taskinfo.ofo5,11)}
                </Link>
            },
        },
        {
            title: '6',
            key: 'totalofo6',
            render: taskinfo=> {
                return <Link 
                to={{
                  pathname: `/tasksingle/${taskinfo.id}/6/1` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                }}>
                {spandata(taskinfo.ofo6,11)}
                </Link>
            },
        } ]
    },
    {
        title: 'Очно-заочная',
        key: 'totalozf',
        children: [
            {
                title: '1',
                key: 'totalozf1',
                render: taskinfo=> {
                    return <Link 
                    to={{
                      pathname: `/tasksingle/${taskinfo.id}/1/7` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                    }}>
                    {spandata(taskinfo.ozf1,11)}
                    </Link>
                },
            },
    {
        title: '2',
        key: 'totalozf2',
        render: taskinfo=> {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/2/7` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.ozf2,11)}
            </Link>
        },
    },
    {
        title: '3',
        key: 'totalozf3',
        render: taskinfo=> {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/3/7` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.ozf3,11)}
            </Link>
        },
    },
    {
        title: '4',
        key: 'totalozf4',
        render: taskinfo=> {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/4/7` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.ozf4,11)}
            </Link>
        },
    }]
    },
    {
        title: 'Заочная форма',
        key: 'totalzfo',
        children: [
            {
                title: '1',
                key: 'totalzfo1',
                render: taskinfo=> {
                    return <Link 
                    to={{
                      pathname: `/tasksingle/${taskinfo.id}/1/2` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                    }}>
                    {spandata(taskinfo.zfo1,11)}
                    </Link>
                },
            },
    {
        title: '2',
        key: 'totalzfo2',
        render: taskinfo=> {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/2/2` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.zfo2,11)}
            </Link>
        },

    },
    {
        title: '3',
        key: 'totalzfo3',
        render: taskinfo=> {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/3/2` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.zfo3,11)}
            </Link>
        },

    },
    {
        title: '4',
        key: 'totalzfo4',
        render: taskinfo=> {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/4/2` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.zfo4,11)}
            </Link>
        },

    },
    {
        title: '5',
        key: 'totalzfo5',
        render: taskinfo=> {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/5/2` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.zfo5,11)}
            </Link>
        },

    },
    {
        title: '6',
        key: 'totalzfo6',
        render: taskinfo=> {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/6/2` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.zfo6,11)}
            </Link>
        },

    },
    {
        title: '7',
        key: 'totalzfo7',
        render: taskinfo=> {
            return <Link 
            to={{
              pathname: `/tasksingle/${taskinfo.id}/7/2` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
            }}>
            {spandata(taskinfo.zfo7,11)}
            </Link>
        },

    }
    ]
    },
        
        
        
    ];
    
    useEffect(() => {
        setLoading(true);
        const idtask = parseInt(props.match.params.id, 10);
        API_getstatoop(idtask).then(data => {
            let index = 0;
            settaskinfo(
                data.map(s => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        })
        .finally(() => setLoading(false));
    }, [props.match.params.id]);
    
            
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Статистика по загрузке документов по ОПОП" />
            <Menu
                            mode="horizontal"
                            style={{ height: '100%' }}
                            activeKey={props.history.location.pathname}
                            selectedKeys={[props.history.location.pathname]}
                        >
                            
                            <Menu.Item key="/bak">
                                <NavLink to="/stat/03">
                                    Бакалавриат
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/spec">
                                <NavLink to="/stat/05">
                                    Специалитет
                                </NavLink>
                            </Menu.Item>

                            <Menu.Item key="/mag">
                                <NavLink to="/stat/04">
                                    Магистратура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/asp">
                                <NavLink to="/stat/06">
                                    Аспирантура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/ord">
                                <NavLink to="/stat/08">
                                    Ординатура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/spo">
                                <NavLink to="/stat/02">
                                    СПО
                                </NavLink>
                            </Menu.Item>
                        </Menu>
            <Spin  spinning={loading} tip="Подождите идет сбор информации..">            
            <Table columns={columns} dataSource={taskinfo} bordered size="small" pagination={false}
                rowKey ={record => record.id} 

                />
                </Spin>
        </>
        

    )
}

export default StatOOP
