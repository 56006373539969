import React from 'react';
//import {Link} from 'react-router-dom';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Button, Table,  Upload, notification, Form, Modal,Select,Input} from 'antd';
import { useState, useEffect } from 'react';
//import { API_GetHeader } from './../api/getheader';
//import { API_GetResponsible } from './../api/getresponsible';
import { API_GetQuestionList} from '../api/question';
import { API_OOPGet} from '../api/oop';
import { API_getFiles, API_uploadFile, API_deleteFile, API_downloadFile,API_addURL } from './../api/file';
import TextArea from 'antd/lib/input/TextArea';
import {spandata} from './../api/addon';
import {compareByAlph} from './../api/addon';
var moment = require('moment');
const routes = [{ label: 'Главная', path: '/' }, { label: 'ОПОП', path: '/task' }]
var first = true
const AccredDocUpload = props => {
    const { Option } = Select;
    const [pageName, setPageName] = useState('Загрузка документов');
    const [loading, setLoading] = useState(false);
    const [peopleFiles, setPeopleFiles] = useState([]);
    const [comment, setComment] = useState(undefined);
    const [responsible, setapiqueston] = useState([]);
    const [file, setFile] = useState(undefined);
    const [fileList, setFileList] = useState([]);
    const [desc, setdesc] = useState(undefined);
    const [choicetype,choicetypevalue]= useState(false);
    const [role,setrole]=useState(0);
// делает список вопросов

useEffect(() => {
    API_GetQuestionList(props.match.params.id).then(data => setapiqueston(data));
}, [props.match.params.id]);


const roles=['','Эксперт ДОКО','Руководитель экспертной группы КУМС',
     'Секретарь экспертной группы КУМС',
     'Эксперт КУМС',
     'Руководитель рабочей группы',
     'Член рабочей группы'] 

function findWithAttr(array, value) {
    for(var i = 0; i < array.length; i += 1) {
        if(parseInt(array[i]["id"]) === parseInt(value)) {
             return array[i]["name"];
        }
    }
    return false;
}


const result = findWithAttr(responsible,props.match.params.question);
function showformdata(result) {
    
    if (result!==false) {
        return (<Form.Item label="Наименование документа">
    <span>{spandata(result,1)}</span>
</Form.Item>) } else  {
return (
    <span>Ошибка</span>
)
}
}


// это возвращает привязанные файлы уже 
// если sectionid =1 oop_full
// если sectionid =2 group_stud
// если sectionid =3 fio студента запрос


var buttondisabled=false
var formdisabled=false


useEffect(() => {
    const valueid = parseInt(props.match.params.id, 10);
    const course = parseInt(props.match.params.course, 10);
    const form = parseInt(props.match.params.form, 10);
    if ((valueid,course,form)) {
        getHeader(valueid,course,form);
        
    }
// eslint-disable-next-line     
}, [props.match.params.id,props.match.params.course,props.match.params.form]);

const getHeader = (valueid,course,form) => {
//	if id_Question===403 and role>1 set @id_role=0
//	if id_Question===604 and role>1 set @id_role=0
//	if id_Question===800 and role>1 set @id_role=0
//	if id_Question===989 and role>1 set @id_role=0
//	if id_Question===1102 and_role>1 set @id_role=0

    API_OOPGet(valueid,course,form).then(response => {
        setrole(response[0].role); // ставим роль   
        var checkdoko=true;
        var checkrole=response[0].role;
    const id_Question = parseInt(props.match.params.question, 10);
    if ((id_Question===106)&&(checkrole>1))  checkdoko=false;
    if ((id_Question===403)&&(checkrole>1))  checkdoko=false;
    if ((id_Question===604)&&(checkrole>1))  checkdoko=false;
    if ((id_Question===800)&&(checkrole>1))  checkdoko=false;
    if ((id_Question===989)&&(checkrole>1))  checkdoko=false;
    if ((id_Question===1102)&&(checkrole>1))  checkdoko=false;
    if (!checkdoko)   {
        setPageName(`Ошибка доступа`)
        notification.error({ message: `Ошибка `, description: 'Данный раздел доступен только для загрузки ДОКО' });
        buttondisabled=true
        formdisabled=true
    } else {
        setPageName(`${response[0].fgos},${response[0].code},${response[0].fak} ${response[0].napr} ${response[0].profile}, ${response[0].course} курс, ${response[0].form} форма`)
    }
    }).catch(function(error) {
        
        try {
            setPageName(`Ошибка доступа`)
            notification.error({ message: `Ошибка 1`, description: 'У вас нет доступа или раздел отсутствует' });
            buttondisabled=true
            formdisabled=true
        } catch {
            notification.error({ message: `Ошибка 2`, description: 'У вас нет доступа или раздел отсутствует' });
            setPageName(`Ошибка доступа`)
            buttondisabled=true
            formdisabled=true
            setPageName(`У вас нет доступа или раздел отсутствует`)
            
        }
    });;
    
};




    useEffect(() => {
        const valueid = parseInt(props.match.params.id, 10);
        const course = parseInt(props.match.params.course, 10);
        const form = parseInt(props.match.params.form, 10);
        const question= parseInt(props.match.params.question, 10);
        if ((valueid,course,form,question)) {
            //:id/:course/:form/:question
            getFiles(desc);
        }
         // eslint-disable-next-line
    }, [props.match.params.id,props.match.params.course,props.match.params.form,props.match.params.question,desc]);
    
    const getFiles = (desc) => {
        const valueid = parseInt(props.match.params.id, 10);
        const course = parseInt(props.match.params.course, 10);
        const form = parseInt(props.match.params.form, 10);
        const question= parseInt(props.match.params.question, 10);
        API_getFiles(valueid,course,form,question,desc).then(response => {
            if (response.data) {
                
                setPeopleFiles(response.data); // сюда должны
            }
        });
    };
    // при доооавлениии ссылки
    const AddUrl = ()=> {
        let commentid=comment.replace(/[^0-9]+/g, '');
        if (commentid==="") {
            notification.error({ message: `Ошибка`, description: 'ссылка должно быть число, операция прервана' });
        } else {
        const json = {
            comment: commentid,
            idquestion: parseInt(props.match.params.question,10),
            idvalue: parseInt(props.match.params.id,10),
            course: parseInt(props.match.params.course,10),
            form: parseInt(props.match.params.form,10),
        };
        API_addURL(json)
                .then(response => {
                    setComment(undefined);
                    setFileList([]);
                    getFiles();
                    if (response.data.IDFile===0) {
                        notification.error({ message: `Ошибка`, description: 'Ссылка не существует или файл удален' });

                    } else {
                        notification.success({ message: `Ссылка добавлена` });

                    }

                })
                .catch(error => {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                })
            }
    }


    // загрузка файла
    const uploadFile = (file) => {
        var reader = new FileReader();
        setLoading(true);
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result.length>128000000) {
                notification.error({ message: `Ошибка`, description: 'Слишком большой файл '+Math.round(reader.result.length/1.5/(1024*1024))+ ' МБ '  }); 
                setLoading(false);
                return
            }
            console.log(reader.result.length);
            let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');
            const json = {
                comment: comment,
                idquestion: parseInt(props.match.params.question,10),
                idvalue: parseInt(props.match.params.id,10),
                course: parseInt(props.match.params.course,10),
                form: parseInt(props.match.params.form,10),
                fileName: file.name,
                fileBody: fileSource
            };
            
            API_uploadFile(json)
                .then(response => {
                    setComment(undefined);
                    setFile(undefined);
                    setFileList([]);
                    getFiles();
                    if (response.data.IDFile===0)
                    notification.error({ message: `Ошибка`, description: 'У вас нету доступа на загрузку сюда файлов' });
                    else
                    notification.success({ message: `Файл загружен` });
                })
                .catch(error => {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                })
                .finally(() => setLoading(false));
        };
    };

    const deleteFile = idFile => {
        Modal.confirm({
            title: 'Вы уверены?',
            centered: true,
            onOk: () => {
                setLoading(true);
                API_deleteFile(idFile)
                    .then(response => {
                        
                        getFiles();
                        switch(response) {
                        case 'done':  notification.success({ message: `Файл удален` }); break;
                        case 'print': notification.warning({ message: `Ошибка`, description: 'Нельзя удалять файл, с статусом "Печать"' }); break;   
                        case 'url': notification.warning({ message: `Ошибка`, description: 'Нельзя удалять файл, где есть ссылка' }); break;
                        case 'expert': notification.warning({ message: `Ошибка`, description: 'Нельзя удалять файл, где есть замечания эксперта, нужно добавить файл с точно таким же наименованием чтобы не менялся id' }); break;
                        case 'status': notification.warning({ message: `Ошибка`, description: 'Нельзя удалять файл, где есть статусы где на доработку, нужно добавить файл с точно таким же наименованием чтобы не менялся id"' }); break;
                        case 'done url':  notification.success({ message: `ссылка удалена` }); break;
                        default: notification.warning({ message: `Ошибка`, description: 'неизвестная пока ошибка' }); break;
                        }
                    })
                    .catch(error => {
                        try {
                            notification.error({ message: `Ошибка`, description: 'У вас нету доступа' });
                            //notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                    })
                    .finally(() => setLoading(false));
            },
        });
    };

    const downloadFile = (idFile,namefile) => {
        setLoading(true);
        API_downloadFile(idFile,namefile)
            .then(response => {
                notification.info({ message: `Файл загружается: `});
            })
            .catch(error => {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
            .finally(() => setLoading(false));
    };

    const docColumns = [
        {
            title: '№',
            dataIndex: 'rowNumber',
            key: 'id',
        },
        /*
        {
            title: 'Замечания эксперта',
            key: 'cid',
            render: row => {
                if (row.fio>0) {
                //return <Button icon="download" onClick={() => downloadFile(row.id)} loading={loading} />;
                return <Link 
                        to={{
                          pathname: `/getexpertdoc/${row.id}` // id программы/курс/форма 1=очка,2=заочка,7-очно заочное
                        }}>
                        {spandata('Замечаний:'+row.fio,11)}
                        </Link>
                } else {
                    return 'Замечаний: 0'
                }
            },
        },*/
        {
            title: 'Имя файла',
            dataIndex: 'docname',
            sorter: (a, b) => compareByAlph(a.docname, b.docname),
            key: 'docname',
            
        },
        { title: 'Описание', key: 'comment',
            sorter: (a, b) => compareByAlph(a.comment, b.comment),
            render: row => {
          if (row.id>0) {
            if   (row.url===undefined) 
                return spandata(row.comment+"|"+row.id,12) 
            else 
                return spandata(row.comment+' (ссылка на файл ) '+row.url) 
            }
        }
        },
        {
            title: 'Дата загрузки',
            key: 'dateupload',
            dataIndex: 'dateupload',
            sorter: (a, b) => moment(a.dateupload).unix() - moment(b.dateupload).unix()
        },
        {
            title: 'Скачать',
            align: 'center',
            width: 120,
            render: row => {
                return <Button icon="download" onClick={() => downloadFile(row.id,row.docname)} loading={loading} />;
            },
        },
        {
            title: 'Удалить',
            align: 'center',
            width: 120,
            render: row => <Button icon="delete" type="danger" loading={loading} onClick={() => deleteFile(row.id)} />,
        },
    ];
    let index=0
    const docData = () =>
        peopleFiles.map(s => {
            index++;
            return {
                ...s,
                rowNumber: index,
            };
        });
if (first)  {
routes.push({label: 'Вернуться в перечень', path: '/tasksingle/'+props.match.params.id+'/'+props.match.params.course+'/'+props.match.params.form});    
    first = false
}
if ((pageName!=='Ошибка доступа'))  {
    return (
        <>
        Ваша роль - {spandata(roles[role],11)}
        <PageBreadcrumbs routes={routes} />
        
            <PageTitle title={pageName} />
            
            <p style={{ fontSize: 18, marginBottom: 16, marginTop: 20 }}>Загрузка документов</p>
            
            <Form id="20">
            {showformdata(result)}
           

            <Form.Item label="Определите тип:">
            
            <Select onChange={choicetypevalue} style={{ width: 200 }}>
            <Option value="file">Файл</Option>
            <Option value="url">ссылка</Option>
          </Select>
          <p style={{ fontSize: 16, marginBottom: 10, marginTop: 10}}><b>ВНИМАНИЕ ОБНОВЛЕНИЕ!! В связи с изменением подготовки файлов</b></p>
          <p style={{ fontSize: 14, marginBottom: 10, marginTop: 10}}>теперь можно заменять файлы на любое имя (при условии что статус в обработке или на доработку), но нужно обязательно в комментарии написать <b>replace</b> имя заменяемого файла</p>
          <p style={{ fontSize: 14, marginBottom: 10, marginTop: 10}}>Например:  <u>replace Б1.Б.23 Численные методы.docx</u>  Тогда файл  <b>Б1.Б.23 Численные методы.docx</b> будет заменен на новый файл</p>
          <p style={{ fontSize: 14, marginBottom: 10, marginTop: 10}}>при загрузке файла -<b> "файл"</b>; при условии того, что размещаемый файл будет повторять уже размещенный файл, необходимо выбрать <b>"ссылка"</b> и в окно "описание документа" поставить номер файла, например <b>30</b>. Данное обозначение служит ссылкой на уже размещенный в системе файл. </p>

            
        </Form.Item>

                <Form.Item label={(choicetype!=="url") ? "Описание документа" :"Ссылка - ID файла"} style={{ marginBottom: 5 }} disabled={formdisabled}>
                    <TextArea required value={comment} onChange={e => setComment(e.target.value)} placeholder={(choicetype!=="url") ? "Введите подробное описание документа не более 255 символов, в случае замены пишем replace точное имя файла" :"Введите ID (зеленое число в перечне) загруженного ранее файла"}/>
                </Form.Item>
                <Form.Item label="Файл" required>
                    <Upload
                        accept=".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx,.zip,.rar"
                        fileList={fileList}
                        onChange={info => {
                            let fileList = [...info.fileList];
                            fileList = fileList.slice(-1);
                            setFileList(fileList)
                        }}
                       // disabled = {!result}
                       disabled = {(choicetype==="file" && comment!==undefined) ? false: true}
                        beforeUpload={file => {
                            setFile(file);
                            return false;
                        }}
                    >
                        
                        <Button icon="plus" type="primary" disabled={buttondisabled}>
                            Выбрать файл
                        </Button><p>
                        {(choicetype!=="url") ? spandata("Загрузить (до 90 Мегабайт = 90*1024*1024 байт)",10):"Загрузка отключается при выборе ввода ссылки на файл"}
                        </p>
                    </Upload>
                </Form.Item>
                <Form.Item>
                    <Button id="uploadme" loading={loading}
                        onClick={() => (comment!=="undefined" && file && choicetype==="file") ? uploadFile(file) : (!file && comment!=="" && choicetype==="url" ? AddUrl(): uploadFile(file) )}
                        type="primary"
                        disabled={(comment!=="undefined" && file && choicetype==="file") ? false : (!file && comment!=="" && choicetype==="url" ? false:true )}
//                        disabled={!file  || !comment}
                        style={{ width: 200, display: 'block', margin: '25px auto 0 auto' }}
                    >
                    Сохранить
                    </Button>
                    
                </Form.Item>
            </Form>
            <Form layout="inline">
                <Form.Item label="Поиск по описанию">
                    <Input.Search onSearch={setdesc} style={{width:300}} placeholder="после ввода нажмите Enter" />
                </Form.Item>
            </Form>            
            <Table  rowKey ={record => record.id} 
            pagination={{
                pageSizeOptions: ["50","100"],
                showSizeChanger: true,
              }}
             columns={docColumns} dataSource={docData()} bordered size="small" />
        </>
    );

} else {
return (
        
    <>
    Ваша роль - {spandata(roles[role],11)}
        <PageBreadcrumbs routes={routes} />
        <PageTitle title={pageName} />
            
    </>
)
}
};
export default AccredDocUpload;
