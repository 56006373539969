import React from 'react';
import { useState } from 'react';
import { Form, Input, DatePicker, Button, Modal, Table } from 'antd';


const PeopleCheckForm = props => {
    const [modal, setModal] = useState(false);
    const [people,] = useState([]);
    const { getFieldDecorator } = props.form;

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!(values.surname && values.name && values.patronymic) && !(values.docSerial && values.docNo)) {
                Modal.error({ title: 'Пока непонятно', centered: true });
                return;
            }
         });
    };

    const modalColumns = [
        {
            title: '№',
            dataIndex: 'rowNumber',
            key: 'rowNumber',
        },
        {
            title: 'Анкета',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Действие',
            render: row => (
                <Button
                    onClick={() => {
                        props.moveToPeople(row.idPeople);
                        setModal(false);
                    }}
                >
                    Применить к анкете
                </Button>
            ),
        },
    ];

    return (
        <>
            <p style={{ fontSize: 18, margin: '18px 0 7px 0' }}>Информация об аккредитации - тут надо потом увеличить количество полей</p>
            <Form wrapperCol={{ span: 18 }} labelCol={{ span: 6 }} style={{ width: 600 }} onSubmit={handleSubmit}>
                <Form.Item label="Экспертиза №" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('surname')(<Input placeholder="введите" />)}
                </Form.Item>
                <Form.Item label="Дата начала проверки" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('bornDate')(<DatePicker placeholder="введите дату" format="DD.MM.YYYY" />)}
                </Form.Item>
                <Form.Item label="Дата окончания проверки" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('bornDate')(<DatePicker placeholder="введите дату" format="DD.MM.YYYY" />)}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
                    <Button htmlType="submit">Сохранить</Button>
                </Form.Item>
            </Form>
            <Modal title="Поиск анкеты" visible={modal} onCancel={() => setModal(false)} width={800}>
                <p style={{ fontSize: 14, fontStyle: 'italic', marginBottom: 10 }}>Найдены следующие анкеты</p>
                <Table rowKey="idPeople" columns={modalColumns} dataSource={people.map((p, index) => ({ ...p, rowNumber: index + 1 }))} size="small" bordered />
                <div style={{ textAlign: 'center' }}>
                    <Button
                        type="danger"
                        onClick={() => {
                            setModal(false);
                            props.setPeopleDataFromCheck(props.form.getFieldsValue());
                        }}
                    >
                        Ошибка
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default Form.create()(PeopleCheckForm);
