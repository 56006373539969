import React from 'react';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Button, Table,  Upload, notification, Form, Modal } from 'antd';
import { useState, useEffect } from 'react';
//import { API_GetHeader } from './../api/getheader';
//import { API_GetResponsible } from './../api/getresponsible'; // список экспертов будет тянуться из логинов
import { API_GetQuestionList} from '../api/question';
import { API_OOPGet} from '../api/oop';
import { API_GetAboutFile,API_getExpertFiles, API_ExpertuploadFile, API_ExpertdeleteFile, API_ExpertdownloadFile } from './../api/file';
import TextArea from 'antd/lib/input/TextArea';
import {spandata} from './../api/addon';
const routes = [{ label: 'Главная', path: '/' }, { label: 'ОПОП', path: '/task' }]

const ExpertDocUpload = props => {
    const [pageName, setPageName] = useState('Загрузка документов для экспертов');
    const [loading, setLoading] = useState(false);
    const [peopleFiles, setPeopleFiles] = useState([]);
    const [comment, setComment] = useState(undefined);
    const [questions, setapiqueston] = useState([]);
    const [file, setFile] = useState(undefined);
    const [fileList, setFileList] = useState([]);
    const [fileinfo,setFileInfo]= useState(undefined); // fileinfo массив
    const [question,setquestion] = useState(undefined); 
    const [valueid,setvalueid] = useState(undefined); 
    const [form,setform] = useState(undefined); 
    const [course,setcourse] = useState(undefined); 
    const [role,setrole]=useState(0);

    useEffect(()=> {
        //const id=parseInt(props.match.params.idfile, 10);
        const id=props.match.params.idfile;
        if (id) {
              getFileinfo(id);
        }
    },[props.match.params.idfile]);

    const getFileinfo =(id) => {
        API_GetAboutFile(id).then(response => { // setapifileinfo, далее он сохраняет через  useStart в fileinfo
            if (response.data.ID!==0) {
            setform(response.data.form); // form
            setvalueid(response.data.IDSection); ///id oop
            setcourse(response.data.course); //course
            setquestion(response.data.id_question); //документ
            setFileInfo(`${response.data.fileName}, (${response.data.comment})`)
            }
        });
    };

// делает список вопросов в массив setapiquestion
useEffect(() => {
    API_GetQuestionList(valueid).then(data => setapiqueston(data));
}, [valueid]);
function sethidden(role) {
    if (role>4) 
    return true;
     else 
    return false;
}

// поиск по id наименования
function findWithAttr(array, value) {
    if (value>0) {
    for(var i = 0; i < array.length; i += 1) {
        if(parseInt(array[i]["id"]) === parseInt(value)) {
             return array[i]["name"];
        }
    }
    
}
return false;
}
// вытаскиваем  из списка вопросов нужный вопрос
const result = findWithAttr(questions,question);
function showformdata(result) { // ту должно еще инфа от файла прилететь
    
    if (result!==false) {
        return (
    <span>{spandata(result,1)}</span>
) } else  {
    if (role>4)
    return     <span style={{color:'red'}}>У вас нет доступа на добавление файла</span>
    else
    return     <span>Ошибка</span>
 }
}

// конец списка вопросов

// загружает название файла и все аттрибуты в переменную 
//useEffect(() => {
//    API_GetAboutFile(props.match.params.idfile).then(data => setapifileinfo(data)); // setapifileinfo, далее он сохраняет через  useStart в fileinfo
//}, [props.match.params.idfile]);
//props.match.params.idfile




// конец загрузки

// это возвращает привязанные файлы уже 
// если sectionid =1 oop_full
// если sectionid =2 group_stud
// если sectionid =3 fio студента запрос

  
useEffect(() => {
//    const valueid = parseInt(valueid, 10);
//    const course = parseInt(course, 10);
//    const form = parseInt(form, 10);
    if ((valueid,course,form)) {
        getHeader(valueid,course,form);
        
    }
}, [valueid,course,form]);

const getHeader = (valueid,course,form) => {
    if ((valueid>0)&&(course>0)&&(form>0)) {
    API_OOPGet(valueid,course,form).then(response => {
        if (routes[2]===undefined) {
        routes.push({
            label: 'Обратно',
            path: '/tasksingle/'+valueid+'/'+course+'/'+form,
          });
        }
        setrole(response[0].role); // ставим роль    
        setPageName(` ${response[0].fgos},${response[0].code},${response[0].fak} ${response[0].napr} ${response[0].profile}, ${response[0].course} курс, ${response[0].form} форма`)
    });
}
};
// тут надо catch еще воткнуть с noty


    

    useEffect(() => {
        //:id/:course/:form/:question
            getFiles();
         // eslint-disable-next-line
    }, []);
    
    const getFiles = () => {
        const idfile= parseInt(props.match.params.idfile, 10);
        // idfile - это файл эксперта
        //API_getExpertFiles(parentid).then(response => {
        API_getExpertFiles(idfile).then(response => {
            if (response.data) {
                setPeopleFiles(response.data); // сюда должны
            }
        });
    };

    // загрузка файла
    const ExpertuploadFile = (file) => {
        setLoading(true);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');
            const json = {
                comment: comment,
                idquestion: parseInt(question,10),
                idvalue: parseInt(valueid,10),
                course: parseInt(course,10),
                form: parseInt(form,10),
                fileid: parseInt(props.match.params.idfile,10),
                fileName: file.name,
                fileBody: fileSource
            };
            API_ExpertuploadFile(json)
                .then(response => {
                    setComment(undefined);
                    setFile(undefined);
                    setFileList([]);
                    getFiles();
                    if (response.data.IDFile===0)
                    notification.error({ message: `Ошибка`, description: 'У вас нету доступа на загрузку сюда файлов'+response.data.IDFile });
                    else
                    notification.success({ message: `Файл загружен` });
                })
                .catch(error => {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                })
                .finally(() => setLoading(false));
        };
    };

    const ExpertdeleteFile = idFile => {
        Modal.confirm({
            title: 'Вы уверены?',
            centered: true,
            onOk: () => {
                setLoading(true);
                API_ExpertdeleteFile(idFile)
                    .then(response => {
                        getFiles();
                        notification.success({ message: `Файл удален` });
                    })
                    .catch(error => {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                    })
                    .finally(() => setLoading(false));
            },
        });
    };

    const ExpertdownloadFile = (idFile,namefile) => {
        setLoading(true);
        API_ExpertdownloadFile(idFile,namefile)
            .then(response => {
                notification.info({ message: `Файл загружается: `});
            })
            .catch(error => {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
            .finally(() => setLoading(false));
    };

    const docColumns = [
        {
            title: '№',
            dataIndex: 'rowNumber',
            key: 'id',
        },
        {
            title: 'Замечания эксперта',
            key: 'fio',
            dataIndex: 'comment',
//            render: row => {
  //              return <Button icon="download" onClick={() => downloadFile(row.id)} loading={loading} />;
//            },
        },
        {
            title: 'Имя файла',
            dataIndex: 'docname',
            key: 'docname',
            
        },
        {
            title: 'Описание к файлу',
            dataIndex: 'parentname',
            key: 'parentname',
        },
        {
            title: 'Дата загрузки',
            key: 'dateupload',
            dataIndex: 'dateupload',
        },
        {
            title: 'Скачать',
            align: 'center',
            width: 120,
            render: row => {
                return <Button icon="download" onClick={() => ExpertdownloadFile(row.id,row.docname)} loading={loading} />;
            },
        },
        {
            title: 'Удалить',
            align: 'center',
            width: 120,
            
        render: row => {
            
            if (role<5)
                return (
                    <Button icon="delete" type="danger" loading={loading} onClick={() => ExpertdeleteFile(row.id)} />
                )
            
    },
        },
    ];
    let index=0
    const docData = () =>
        peopleFiles.map(s => {
            index++;
            return {
                ...s,
                rowNumber: index,
            };
        });
     const roles=['','Эксперт ДОКО','Руководитель экспертной группы КУМС',
     'Секретарь экспертной группы КУМС',
     'Эксперт КУМС',
     'Руководитель рабочей группы',
     'Член рабочей группы']   
        //routes.push({label: 'Вернуться в перечень', path: '/tasksingle/'+props.match.params.id+'/'+props.match.params.course+'/'+props.match.params.form});    
if (fileinfo!==undefined) {
    return (
        <>
        Ваша роль - {spandata(roles[role],11)}
        <PageBreadcrumbs routes={routes} />
            <PageTitle title={pageName} />
            <p style={{ fontSize: 18, marginBottom: 16, marginTop: 20 }}>Загрузка документов для эксперта</p>
            <Form style={{ width: 600, margin: '0 auto' }} hidden={sethidden(role)}>
            {showformdata(result)}, добавьте к файлу {spandata(fileinfo,11)}
                <Form.Item label="Замечание эксперта" style={{ marginBottom: 5 }}>
                    <TextArea required value={comment} onChange={e => setComment(e.target.value)} placeholder="Введите подробное описание документа не более 1000 символов" />
                </Form.Item>
                <Form.Item label="Файл" required>
                    <Upload
                        accept=".doc,.docx,.pdf,.jpg,.jpeg"
                        fileList={fileList}
                        onChange={info => {
                            let fileList = [...info.fileList];
                            fileList = fileList.slice(-1);
                            setFileList(fileList)
                        }}
                        disabled = {!result}
                        beforeUpload={file => {
                            setFile(file);
                            return false;
                        }}
                    >
                        
                        <Button icon="plus" type="primary">
                            Выбрать файл
                        </Button><p>
                        {spandata("Загрузить (до 64 мегабайт)",10)}
                        </p>
                    </Upload>
                </Form.Item>
                <Form.Item>
                    <Button
                        onClick={() => ExpertuploadFile(file)}
                        type="primary"
                        disabled={!file  || !comment}
                        style={{ width: 200, display: 'block', margin: '25px auto 0 auto' }}
                    >
                    Сохранить    
                    </Button>
                </Form.Item>
            </Form>
            <Table  rowKey ={record => record.id} 
             columns={docColumns} dataSource={docData()} bordered size="small" />
        </>
    );
                    } else {
    return (
        <>
        <PageBreadcrumbs routes={routes} />
            <PageTitle title="Нет доступа работы с данным файлом, он есть только у пользователей включенных в программу" />
            </> 
    );
    }
};

export default ExpertDocUpload;
