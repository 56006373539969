import React from 'react';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import {Button,Form, Select, Input,Modal,notification,Upload } from 'antd';
import { useState, useEffect } from 'react';
import { API_GetQuestionOne,API_SaveQuestion } from './../api/question'; // вытаскивает выбранный вопрос
import TextArea from 'antd/lib/input/TextArea';
import {API_uploadFileTemplate } from './../api/file';
//import {PageTitle2} from './../api/addon';

const routes = [{ label: 'Главная', path: '/' }, { label: 'Вопросы по разделам', path: '/docforstatement' }]
const { Option } = Select;
const QuestionSingle = props => {
const { getFieldDecorator } = props.form;
const [loading, setLoading] = useState(false);
const [choicefgos,choicefgosvalue]= useState(false);

const state = {
    lvls: [],
    lvlsname: [],
  };    
state.lvls[0]='Все ООП';
state.lvls[3]='Бакалавриат';
state.lvls[4]='Магистратура';   
state.lvls[5]='Специалитет'; 
state.lvls[6]='Аспирантура'; 
state.lvls[8]='Ординатура'; 

state.lvlsname['Все ООП']=0; 
state.lvlsname['Аспирантура']=6; 
state.lvlsname['Ординатура']=8; 
state.lvlsname['Магистратура']=4;   
state.lvlsname['Бакалавриат']=3;
state.lvlsname['Специалитет']=5; 


const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
        if (!(values.choicename && values.choicecode && values.choicetypes)) {
            Modal.error({ title: 'Ошибка', centered: true });
            return;
        } 
        values.choicelvl=state.lvlsname[values.choicelvl]
        values.id=props.match.params.id
        API_SaveQuestion({
            id: parseInt(values.id,10),
            code: values.choicecode,
            name: values.choicename,
            types: values.choicetypes,
            design: values.design
        }).then(idQuestion => {
            getdataquestion(props.match.params.id);
        });
//        Modal.success({ title: 'Данные сохранены', centered: true });
     });
};



const getdataquestion = id => {
    API_GetQuestionOne(id).then(response => {
        props.form.setFieldsValue({
    choicetypes: response[0].idtypes, //
    design: response[0].design, /// код дизайна внутри таблицы смотри api/addon.js 
    comment: response[0].comment, // комментарий необязательно для раздела
    docname: response[0].docname, // числовой docname -  служит для индикатора определения где есть шаблон, он обновляется после загрузки
    choicename: response[0].choicename, // полное описание имя документа 
    choicecode: response[0].choicecode, // код - служит индикатором для определения где будет загрузка файлов
    choicelvl: state.lvls[props.match.params.lvl], // уровень 4 - магистратура 35 - специалитет бакалавриат 
    choicefgos : response[0].fgos // фгос для загрузки в нужную папку, шаблона если идентичные ОПОП имеют разные ФГОС 3++, ФГОС3
    })
    });
    };
    
useEffect(() => { 
if ((props.match.params.id)) {
    getdataquestion(props.match.params.id);

} // eslint-disable-next-line
}, [props.match.params.id]); 

const uploadFile = (file) => {
    setLoading(true);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');
        const json = {
            fileName: file.name,
            fileBody: fileSource,
            fgos: choicefgos,
            idquestion: props.match.params.id, // по идее он должен делать select и находить docname при условии что сохранили
            lvl:props.match.params.lvl
        };
        API_uploadFileTemplate(json)
            .then(response => {
                notification.success({ message: `Файл загружен` });
            })
            .catch(error => {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
            .finally(() => setLoading(false));
    };
};


return (
    
<>
    <PageBreadcrumbs routes={routes} />
    <PageTitle title="Редактирование документа" />
    <Form style={{ width: 600, margin: '0 auto' }}  onSubmit={handleSubmit}>
    <Form.Item label="Описание" style={{ marginBottom: 5 }}>
            {getFieldDecorator('choicename')(
            <TextArea placeholder="Введите наименование документа" />
            )}
         </Form.Item>
    <Form.Item label="Уровень" style={{ marginBottom: 5 }}>
            {getFieldDecorator('choicelvl')(
            <Input placeholder="Имя файла шаблона" disabled/>
            )}
        </Form.Item>
        <Form.Item label="Определите">
            
            <Select onChange={choicefgosvalue} style={{ width: 200 }}>
            <Option value="fgos">ФГОС</Option>
            <Option value="fgos3plus">ФГОС ВО 3++</Option>
          </Select>
            
        </Form.Item>
        <Form.Item label="Тип" style={{ marginBottom: 5 }}>
            {getFieldDecorator('choicetypes')(
            <Select style={{ width: 200 }}>
            <Option value="1">По умолчанию</Option>
            <Option value="2">Для ООП</Option>
            <Option value="4">Для курса</Option>
          </Select>
            )}
         </Form.Item>
         <Form.Item label="Комментарии" style={{ marginBottom: 5 }}>
            {getFieldDecorator('comment')(
            <Input placeholder="комментарий не обязательно" />
            )}
         </Form.Item>
         <Form.Item label="Имя файла шаблона" style={{ marginBottom: 5 }}>
            {getFieldDecorator('docname')(
            <Input placeholder="Имя файла шаблона" disabled/>
            )}
         </Form.Item>
         <Form.Item label="Дизайн" style={{ marginBottom: 5 }}>
            {getFieldDecorator('design')(
            <Input placeholder="Дизайн см файл addon.js" />
            )}
         </Form.Item>
        <Form.Item label="Код раздела, возможно потребуется для создания директорий, также служит индикатором для загрузки файла" style={{ marginBottom: 5 }}>
            {getFieldDecorator('choicecode')(
            <Input placeholder="Код раздела" />
            )}
         </Form.Item>
        
         <Form.Item label="Файл" required>
         {getFieldDecorator('filename')(
                    <Upload
                        accept=".doc,.docx"
                        beforeUpload={file => {
                            uploadFile(file);
                            return false;
                        }}
                    > 
                        <Button icon="plus" type="primary"  loading={loading}
                        disabled={!choicefgos}
                        >
                            
                            Выбрать файл
                        </Button>
                    </Upload>
                    )}
        </Form.Item>
         <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
                    <Button htmlType="submit">Сохранить</Button>
                </Form.Item>      
            </Form>
            
        </>
    );
};

export default Form.create()(QuestionSingle);