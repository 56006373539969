import React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { useEffect, useState } from 'react';
import { API_GetResponsible} from '../api/getresponsible';
import { API_OOPGet } from '../api/oop';
import { Table,Spin,notification} from 'antd';
import {spandata} from './../api/addon';
import {compareByAlph} from './../api/addon';
import { API_Online } from '../api/getheader';
import getColumnSearchProps from './../components/getColumnSearch'

const routes = [{ label: 'Главная', path: '/' }, { label: 'Состав рабочих групп и экспертов', path: '/aboutgroup/0/1/1' }]




const Responsible = (props) => {
    const getOnline = () => {
        API_Online().then(response => {
            if (parseInt(response.Status)===0) {
                notification.error({ message: `Ваша сессия истекла`, description: "Нужно перезайти" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
            if (parseInt(response.Status)===2) {
                notification.error({ message: `Авторизация успешна, но доступ закрыт`, description: "Ваши данные не найдены в рабочих группах модуля \"Аккредитации\" , обратитесь в директору/декану вашего учебного подразделения" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
        }).catch(function(error) {
            try {
                notification.error({ message: `Ошибка 1`, description: "неопознанная ошибка" });
                
            } catch {
                notification.error({ message: `Ошибка 2`, description: "неопознанная ошибка" });
            }
        });;
    };
    useEffect(() => {
            getOnline();
    // eslint-disable-next-line            
    }, []);    
// header
    // header
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            title: '№',
            dataIndex: 'rowNumber',
            key: 'rowNumber',
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            key: 'role',
            sorter: (a, b) => compareByAlph(a.role, b.role),
            ...getColumnSearchProps('role','Роль'),
            
        },
        {
            title: 'ФИО',
            key: 'fio',
            sorter: (a, b) => compareByAlph(a.fio, b.fio),
            ...getColumnSearchProps('fio','ФИО'),
            render: taskinfo=> {
                if (localStorage.getItem('login')==='admin') {
                    return <a  href={'/docs/'+taskinfo.id}>
                    {spandata(taskinfo.fio,10)}
                    </a>    
                } else {
                return <a target="_blank"  rel="noopener noreferrer" href={'https://www.s-vfu.ru/staff/'+taskinfo.id}>
                {spandata(taskinfo.fio,10)}
                </a>
                }
            },
            
        },
        {
            title: 'Должность',
            ...getColumnSearchProps('dolzn','Должность'),
            sorter: (a, b) => compareByAlph(a.dolzn, b.dolzn),
            dataIndex: 'dolzn',
            key: 'dolzn',
        },
        {
            title: 'E-mail',
            dataIndex: 'corpemail',
            key: 'corpemail',
        },
        
    ]; 
    

if (props.match.params.id==='0') 
    columns.push({
            title: 'Подразделение',
            dataIndex: 'depname',
            ...getColumnSearchProps('depname','Подразделение'),
            sorter: (a, b) => compareByAlph(a.depname, b.depname),
            key: 'depname',
    });



    const PageTitle2 = props => <p style={{ fontSize: 16, margin: '5px 0 10px 0' }}><b>{props.title}</b></p>


    const getHeader2 = (valueid,course,form) => {
        API_OOPGet(valueid,course,form).then(response => {
            //debugger; 
            if (valueid===0)
                setPageName(``)
                else
                setPageName(`${response[0].fgos},${response[0].code},${response[0].fak} ${response[0].napr} ${response[0].profile}`)
        });
    };


    useEffect(() => {
        const valueid = parseInt(props.match.params.id, 10);
        const course = parseInt(props.match.params.course, 10);
        const form = parseInt(props.match.params.form, 10);
        if ((valueid,course,form)) {
            getHeader2(valueid,course,form);
            
        }
    }, [props.match.params.id,props.match.params.course,props.match.params.form]);
    
    
    
    const [pageName, setPageName] = useState([]);
// end header
    

    const [groupinfo, setAboutgroup] = useState([]);

    useEffect(() => {
        setLoading(true);
        API_GetResponsible(props.match.params.id).then(data => {
            
            let index = 0;
            setAboutgroup(
                data.map(s => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        })
        .finally(() => setLoading(false));
    }, [props.match.params.id]);

    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <PageTitle2 title={pageName} />            
            <PageTitle title="Состав рабочих групп и экспертов" />
            <Spin  spinning={loading} tip="Подождите, идет проверка работающих сотрудников...">
            </Spin>
            <Table columns={columns} dataSource={groupinfo} bordered size="small" pagination={false}
                rowKey ={record => record.id} 
                
                />
            
        </>
    )
}


export default Responsible
