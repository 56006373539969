import React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { API_GetDocument} from '../api/getdocument';

const routes = [{ label: 'Главная', path: '/' }, { label: 'Задачи', path: '/task' }]

const columns = [
    {
        title: '№',
        dataIndex: 'rowNumber',
        key: 'rowNumber',
    },
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Данные',
        dataIndex: 'value',
        key: 'value',
    },
];


const GetDocument = (props) => {
    const [data, GetDocument] = useState([]);
    useEffect(() => {
        const iddoc = parseInt(props.match.params.id, 10);
        const idtype = parseInt(props.match.params.uid, 10);
        API_GetDocument(iddoc,idtype).then(data => {
            let index = 0;
            GetDocument(
                data.map(s => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        });
    }, [props.match.params.id,props.match.params.uid]);

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Документы" />
            <Table columns={columns} dataSource={data} bordered size="small"  pagination={false}
            rowKey ={record => record.id} 
            onRow={record => {
                return {
                    onClick: () => props.history.push(`/setdocument/${record.id}/${props.match.params.uid}/${props.match.params.id}`),
                };
            }}
            />
        </>
    )
}

export default GetDocument
