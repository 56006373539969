import axios from 'axios'

export const API_login = (login, password) => {
    var type=0
    if (login==='admin') {
        type=0
    } else {
        type=1
    }
    return axios
   .post('https://accredapi.s-vfu.ru/accred/login',{
// .post('http://88.82.173.57:8080/accred/login',{
           login: login,
           pwd: password,
           type: type,
        })
        .then(function(response) {
            return response.data
        })
        
    
}

export const DirectAPI_login = (session) => {
    return axios
    // для прямого обращения с указаниаем сервиса
//    .post('http://88.82.173.57:8081/auth/direct',{
    .post('https://id.s-vfu.ru/auth/direct',{
        session: session,
        service: "accred"
    })
        .then(function(response) {
            console.log(session)
            return response.data
        })
        
    
}