import axios from 'axios';

const instance = axios.create({
baseURL: 'https://accredapi.s-vfu.ru/accred/',
//baseURL: 'http://88.82.173.57:8080/accred/',
    headers: {
        Authorization: {
            toString() {
                return `Bearer ${localStorage.getItem('accessToken')}`;
            },
        },
    },
});

export default instance;
